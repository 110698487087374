<template>
  <div class>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
          <vs-td style="align-item-center">
            {{ indextr+1 }}
          </vs-td>
          <vs-td style="align-item-center">
            {{ tr.sku_code }}<br />
            {{ tr.item_name }}
          </vs-td>
          <vs-td style="align-item-center">
            {{ tr.qty }} {{ tr.unit_name }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    dataId: Number,
    selected: {},
  },
  watch: {
    dataId() {
      this.reloadData(this.params);
    },
  },
  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
    },
    header: [{ text: "No" },{ text: "Item" }, { text: "Quantity" }],
    responseData: {},
  }),
  methods: {
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/free-good/get-line", {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
            id: this.dataId,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data);
            this.responseData = resp.data;
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>