<template>
  <vx-card title="Free Goods">
    <div class="vx-col md:w-1/1 w-full">
      <!-- <vx-card> -->
        <all></all>
      <!-- </vx-card> -->
    </div>
  </vx-card>
</template>

<script>
import all from "./all.vue";

export default {
  components: {
    all: all,
  },
  data: () => ({
    colorx: "success",
    operatingUnit: {
      selected: null,
      options: [],
    },
  }),
  mounted() {},
  methods: {},
};
</script>
 
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>