<template>
  <div class="tab-text">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <vs-button
                  v-if="dataId != tr.id"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                ></vs-button>
              </vs-td>
              <vs-td :data="a = tr.description.split(';')">
                {{ a[0] }}<br />
                {{ a[1] }}<br />
                {{ a[2] }}<br />
                {{ a[3] }}<br />
                <!-- User : {{ data[indextr].username }} -->
              </vs-td>
              <vs-td>
                <b v-if="tr.type == 'not-return'">GI Code</b> <b v-else>GIR Code :</b> {{ tr.code_gi_gir }}<br>
                <b>IO Code :</b> {{ tr.io_code }}<br>
                <b><b v-if="tr.type == 'not-return'">Invoice Code</b> <b v-else>Customer Return Code</b> :</b> {{ tr.invoice_code }}<br>
                <b><b v-if="tr.type == 'not-return'">Invoice Reference Code</b> <b v-else>Customer Return Reference Code</b> :</b> {{ tr.invoice_ref_code }}
              </vs-td>
              <vs-td>
                <b>Code</b> : {{ tr.outlet_code }}<br>
                <b>Name</b> : {{ tr.outlet_name }}
              </vs-td>
              <vs-td>
                {{ tr.salesman_name }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <detail :data-id="dataId" :selected="selectedData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import detail from "./detail.vue";
import moment from "moment";

export default {
  components: {
    detail,
  },
  props: [],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        operatingUnitId: null,
      },
      header: [
        {text: "Action"},
        {text: "Description"},
        {text: "Code"},
        {text: "Outlet"},
        {text: "Salesman Name"},
      ],
      responseData: {},
      isActive: 1,
      data: [],
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      dataId: 0,
    };
  },
  watch: {},
  methods: {
    clickTr(val) {
      this.closeDetail();
      setTimeout(function () {}, 2000);
      this.dataId = val.id;
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/free-good/gets", {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.records;
            this.responseData = resp.data;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    dateFormat(date) {
      if (date) {
        let dt = new Date(date);
        let d = dt.getDate();
        let m = dt.getMonth() + 1;
        let y = dt.getFullYear();
        return d + "/" + m + "/" + y;
      }
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
</style>